@tailwind base;

@tailwind components;

@tailwind utilities;

:root {
  --min-font-size: 12px;
  --max-font-size: 96px;
  --default-font-size: 1rem;
  --heading-font-size: 1.25rem;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extra: 900;
  --default-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica, sans-serif;
  --serif-font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  --mono-font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--default-font-size);
  line-height: var(--default-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font-family);
}

p,
span,
a,
li,
button {
  font-family: var(--mono-font-family);
  font-weight: var(--font-weight-regular);
}

code,
input,
textarea,
select,
option,
pre {
  font-family: var(--mono-font-family);
  font-weight: var(--font-weight-light);
}
